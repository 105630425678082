@import 'css/layout.scss';
@import 'css/utils.scss';
@import 'css/router.scss';

[data-kit-theme='default'] .ant-steps-item-process .ant-steps-item-icon {
  background: #a21e6d;
  border-color: #a21e6d;
}

[data-kit-theme='default'] .ant-steps-navigation .ant-steps-item::before {
  background-color: #a21e6d;
}
